body {
  background: "#e0e0e0";
}

table {
  border-collapse: collapse;
  color: "#000";
  margin-top: 1rem;
  font-family: Times New Roman, Times, serif;
  font-size: 2rem;
  font-weight: 600;
}

table td {
  padding: 15px;
}

table thead td {
  border: 1px solid #54585d;
  font-size: 13px;
  font-weight: bold;
}

table tbody td {
  color: #000;
  border: 1px solid #dddfe1;
}

#main-component {
  margin-top: 1rem;
}

/*# sourceMappingURL=index.28c008ba.css.map */

body {
			background: '#e0e0e0';
		}

		table {
			border-collapse: collapse;
			font-family: 'Times New Roman', Times, serif;
			font-weight: 600;
			font-size: 2rem;
			color: '#000';
			margin-top: 1rem;
		}

		table td {
			padding: 15px;
		}

		table thead td {
			font-weight: bold;
			font-size: 13px;
			border: 1px solid #54585d;
		}

		table tbody td {
			color: #000;
			border: 1px solid #dddfe1;
		}

		#main-component {
			margin-top: 1rem;
		}